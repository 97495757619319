import React from "react";

export const graphic1 = () => {
  return (
    <div className="w-[50px] flex items-center justify-center relative">
      <div className="border-[2px] border-[#987e48] w-[50px] h-[50px] rounded-[50%] absolute"></div>
      <div className="w-[2px] h-[180px] bg-[#987e48] "></div>
    </div>
  );
};

export const LmLogo = ({ classes }) => {
  return (
    <div
      className={
        "w-[200px] h-[50px] border-[3px] bg-[#00000050] backdrop-blur-sm flex flex-col items-center justify-center border-[#18b54b] " +
        classes
      }
    >
      <div className="uppercase good-font-9 tracking-[7px] text-[17px] text-[#18b54b] font-bold ">
        LMPOLO
      </div>
      <div className="uppercase good-font-4 translate-y-[-3px] tracking-[2px] font-thin text-[#18b54b] text-[9px]">
        world
      </div>
    </div>
  );
};

{
  /* <div className="w-[50px] flex items-center justify-center relative">
<div className="border-[2px] border-[#987e48] w-[50px] h-[50px] rounded-[50%] absolute"></div>
<div className="w-[2px] h-[180px] bg-[#987e48] "></div>
</div> */
}
